var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c('modal', {
    ref: "modal",
    attrs: {
      "title": _vm.title,
      "width": 600,
      "actions": _vm.actions
    }
  }, [_c('div', {
    staticClass: "modal-item"
  }, [_c('div', {
    staticClass: "modal-item__label"
  }, [_vm._v("Добавить через поиск")]), _c('entity-search', {
    attrs: {
      "placeholder": _vm.searchPlaceholder,
      "no-data-text": _vm.searchNoData,
      "text-prop": _vm.searchTextProp,
      "loader": _vm.searchLoader,
      "simple": "",
      "limit": _vm.searchLimit
    },
    on: {
      "select": _vm.onSearchSelect
    },
    scopedSlots: _vm._u([_vm.list.entity === 'product' ? {
      key: "item",
      fn: function ({
        item,
        text
      }) {
        return [_c('div', {
          staticClass: "img"
        }, [item.image ? _c('img', {
          attrs: {
            "src": item.image
          },
          on: {
            "error": function ($event) {
              $event.target.src = require('@/assets/images/img.svg');
            }
          }
        }) : _vm._e()]), _c('span', {
          attrs: {
            "title": item.title
          },
          domProps: {
            "innerHTML": _vm._s(text)
          }
        })];
      }
    } : null], null, true)
  })], 1), _c('div', {
    staticClass: "modal-item"
  }, [_c('div', {
    staticClass: "modal-item__label"
  }, [_vm._v(" " + _vm._s(_vm.textareaLabel) + " ")]), _c('textarea', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.importLines,
      expression: "importLines"
    }],
    staticClass: "nowrap",
    staticStyle: {
      "height": "170px"
    },
    attrs: {
      "placeholder": _vm.textareaPlaceholder
    },
    domProps: {
      "value": _vm.importLines
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.importLines = $event.target.value;
      }
    }
  })])]), _c('modal-list-import-result', {
    ref: "modalListImportResult"
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };