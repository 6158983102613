var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('data-table', {
    ref: "table",
    attrs: {
      "table-id": "lists",
      "columns": _vm.columns,
      "loader": _vm.tableLoader,
      "item-id": "id",
      "configurable": _vm.configurable,
      "paginatable": _vm.paginatable,
      "persist-settings": false,
      "selectable": false,
      "sticky-columns": _vm.small ? 0 : 1
    }
  });
};
var staticRenderFns = [];
export { render, staticRenderFns };