var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('modal', {
    ref: "modal",
    attrs: {
      "title": "Редактирование списка",
      "width": 420,
      "actions": _vm.actions
    }
  }, [_c('div', {
    staticClass: "modal-item"
  }, [_c('div', {
    staticClass: "modal-item__label"
  }, [_vm._v("Название")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.new_list.title,
      expression: "new_list.title"
    }],
    staticClass: "bold",
    attrs: {
      "type": "text",
      "placeholder": "Название списка",
      "autofocus": ""
    },
    domProps: {
      "value": _vm.new_list.title
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.new_list, "title", $event.target.value);
      }
    }
  })]), _c('div', {
    staticClass: "modal-item"
  }, [_c('div', {
    staticClass: "modal-item__label"
  }, [_vm._v("Описание")]), _c('textarea', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.new_list.description,
      expression: "new_list.description"
    }],
    attrs: {
      "placeholder": "Введите описание (опционально)"
    },
    domProps: {
      "value": _vm.new_list.description
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.new_list, "description", $event.target.value);
      }
    }
  })])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };