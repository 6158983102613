import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.filter.js";
import "core-js/modules/esnext.iterator.map.js";
import Modal, { ModalMixin } from "@/components/modals/layouts/Default.vue";
import ModalListImportResult from "@/components/modals/ModalListImportResult.vue";
import EntitySearch from "@/components/EntitySearch.vue";
import { getEntitiesList } from "@/utils/enums";
export default {
  mixins: [ModalMixin],
  props: {},
  data() {
    return {
      list: {
        id: "",
        entity: "",
        mp: ""
      },
      searchLimit: 5,
      importLines: "",
      freshList: false,
      loading: false,
      onComplete: null
    };
  },
  mounted() {},
  methods: {
    onOpen({
      item,
      freshList = false,
      onComplete
    }) {
      this.list = {
        ...item
      };
      this.importLines = "";
      this.freshList = freshList;
      this.onComplete = onComplete;
    },
    searchLoader(query) {
      const actions = {
        category: "category/getCategoryByName",
        product: "product/getProductByName",
        brand: "brand/getBrandByName",
        seller: "seller/getSellerByName"
      };
      return this.$store.dispatch(actions[this.list.entity], {
        query,
        mp: this.list.mp
      });
    },
    onSearchSelect(item) {
      const title = item[this.searchTextProp];
      if (this.list.entity === "product") {
        this.importLines += `\n${title} sku:${item.sku}`;
      } else {
        this.importLines += `\n${title} id:${item[this.idProp]}`;
      }
      this.normalizeLines();
    },
    normalizeLines() {
      const splitted = this.importLines.split("\n");
      this.importLines = splitted.map(line => line.trim()).filter(line => !!line.length).join("\n");
    },
    async submit({
      close
    }) {
      this.loading = true;
      try {
        this.normalizeLines();
        if (!this.importLines.length) {
          close();
          this.loading = false;
          return;
        }
        const result = await this.$store.dispatch("list/listImport", {
          id: this.list.id,
          lines: this.importLines
        });
        this.loading = false;
        close();
        this.$emit("complete", result);
        this.onComplete && this.onComplete(result);
        this.$refs.modalListImportResult.open({
          list: this.list,
          result
        });
      } catch (e) {
        this.loading = false;
      }
    }
  },
  computed: {
    compModalAddButtonTitle() {
      return this.showModalAdd2 ? "Добавить" : "Далее";
    },
    title() {
      return `Добавление ${getEntitiesList("manyGenitive", "lower")[this.list.entity]}`;
    },
    searchPlaceholder() {
      let s = `Поиск по ${getEntitiesList("manyDative", "lower")[this.list.entity]}`;
      if (this.list.entity == 'seller') {
        s += this.list.mp == "wb" ? " или ИНН" : " или ОГРН";
      }
      return s;
    },
    searchNoData() {
      return `${getEntitiesList("many", "capitalize")[this.list.entity]} не найдены`;
    },
    searchTextProp() {
      return getEntitiesList("textProp", null)[this.list.entity];
    },
    idProp() {
      return getEntitiesList("idProp", null)[this.list.entity];
    },
    actions() {
      return [this.freshList ? {
        class: "btn-outline",
        text: "Пропустить этот шаг",
        action: ({
          close
        }) => {
          this.onComplete && this.onComplete();
          close();
        }
      } : "cancel", {
        name: "submit",
        text: "Добавить",
        action: ({
          close
        }) => {
          this.submit({
            close
          });
        },
        loading: this.loading
      }];
    },
    textareaLabel() {
      let result = `Введите список ${getEntitiesList("manyGenitive", "lower")[this.list.entity]}`;
      if (this.freshList) {
        result += " (можно добавить позже)";
      }
      return result;
    },
    textareaPlaceholder() {
      let entity = getEntitiesList("manyGenitive", "lower")[this.list.entity];
      if (this.list.entity === "product") {
        entity = "SKU";
      }
      return `Введите список ${entity} по одному в строке. Либо воспользуйтесь формой поиска`;
    }
  },
  components: {
    Modal,
    EntitySearch,
    ModalListImportResult
  }
};