import NoLists from "./NoLists.vue";
export default {
  props: {
    small: {
      type: Boolean,
      default: false
    },
    text: {
      type: String
    },
    hasItems: {
      type: Boolean,
      default: false
    },
    hasItemsLoaded: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    hasAccess() {
      return !this.$access.check("lists");
    },
    shouldShowModal() {
      return !(this.$store.state.user.user && !this.hasItems) && !(!this.$store.state.user.user || !this.hasItems) && !this.hasAccess;
    }
  },
  // watch: {
  //     shouldShowModal: {
  //         immediate: true,
  //         handler(v) {
  //             if (!v) {
  //                 return;
  //             }
  //             this.$modal.open(() =>
  //                 import("@/components/modals/access/ModalSeoNoSubscription.vue")
  //             );
  //         },
  //     },
  // },
  watch: {
    hasItems() {
      //console.log(this.hasItems);
    }
  },
  components: {
    NoLists
  }
};