var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "lists-page__none",
    class: {
      small: _vm.small
    }
  }, [!_vm.small ? _c('div', {
    staticClass: "lists-page__none-title"
  }, [_vm._v("Мои списки")]) : _vm._e(), _vm.small ? _c('img', {
    staticClass: "loading__img",
    attrs: {
      "src": require('@/assets/images/wireframes/lists.png')
    }
  }) : _vm._e(), _vm.small ? _c('div', {
    staticClass: "lists-page__none-text",
    class: {
      small: _vm.small
    }
  }, [_vm._v(" " + _vm._s(this.text) + " ")]) : _c('div', {
    staticClass: "lists-page__none-text"
  }, [_vm._v(" Здесь вы можете создать свои списки товаров, брендов, продавцов для быстрого доступа к их характеристикам. Также можно создавать списки и добавлять в него позиции прямо из таблицы отчетов ")]), _c('button', {
    staticClass: "btn-outline btn-width-auto",
    class: {
      'connect-btn': _vm.small
    },
    on: {
      "click": _vm.onCreateList
    }
  }, [_c('img', {
    attrs: {
      "src": require(`@/assets/images/icons/plus.svg`)
    }
  }), _vm._v(" Создать первый список ")]), _c('modal-add-list', {
    ref: "modalAddList",
    on: {
      "complete": _vm.onAdded
    }
  }), _c('modal-list-import', {
    ref: "modalListImport",
    on: {
      "complete": function ($event) {
        _vm.refreshKey++;
      }
    }
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };