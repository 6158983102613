import Modal, { ModalMixin } from "@/components/modals/layouts/Default.vue";
export default {
  mixins: [ModalMixin],
  data() {
    const initial_list = {
      id: "",
      title: ""
    };
    return {
      initial_list,
      list: {
        ...initial_list
      },
      loading: false,
      onComplete: null
    };
  },
  mounted() {},
  methods: {
    onOpen({
      item,
      onComplete
    }) {
      this.list = {
        ...item
      };
      this.onComplete = onComplete;
    },
    async submit({
      close
    }) {
      this.loading = true;
      try {
        const result = await this.$store.dispatch("list/removeList", {
          id: this.list.id
        });
        this.loading = false;
        close();
        this.onComplete && this.onComplete(result);
        this.$emit("complete", result);
      } catch (e) {
        this.loading = false;
      }
    }
  },
  computed: {
    actions() {
      return ["cancel", {
        name: "submit",
        class: "btn-blue btn-red",
        text: "Удалить",
        action: ({
          close
        }) => {
          this.submit({
            close
          });
        },
        loading: this.loading
      }];
    }
  },
  components: {
    Modal
  }
};